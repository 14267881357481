<template>
  <div>
    <el-row>
      <el-col :span="24" v-if="isShowForm">
        <Form @successAdd="handleAdd" @onClose="chageIsShowForm(false)" />
      </el-col>
      <el-col :span="24" v-else>
        <el-button class="add-btn" size="mini" type="primary" @click="chageIsShowForm(true)">添加</el-button>
        <Table
          class="table-wrapper"
          title="用户设置"
          hiddenMove
          :tableData="listData"
          :tableCols="getTableCols()"
          :onEdit="handleEdit"
          :onDelete="handleDelete"
          :currentPage="pageNo"
          :total="total"
          :onSizeChange="handlePageSize"
          :onCurrentPageChange="handlePageNo"
        ></Table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Table from '../table'
import Form from './form.vue'
import { tableCols, roleConfig } from './config'
import {
  userCreate,
  userList,
  userDelete,
  rolePut
} from '../../api/apis/users'
export default {
  name: 'User',
  components: { Table, Form },
  data () {
    return {
      listData: [],
      pageNo: 1,
      pageSize: 10,
      keyword: '',
      isShowForm: false,
      total: 0
    }
  },
  computed: {
    params () {
      return {
        pn: this.pageNo,
        ps: this.pageSize,
        keyword: this.keyword
      }
    }
  },
  created () {
    this.getList()
  },
  watch: {
    params () {
      this.getList()
    }
  },
  methods: {
    chageIsShowForm (bool) {
      this.isShowForm = bool
    },
    getTableCols () {
      return tableCols
    },
    async getList () {
      try {
        const data = await userList(this.params)
        if (!data) return
        const { content: { total, list } } = data
        const dataList = list.map(x => ({ ...x, role: roleConfig.find(v => Number(v.key) === Number(x.role))?.value, createUser: x?.create?.realName || '', updateUser: x?.update?.realName || '' }))
        this.total = total
        this.listData = dataList
      } catch (error) {
        console.log(error)
      }
    },
    async handleEdit (data) {
      const { id, role } = data
      const [roleVal] = roleConfig.filter(v => v.value === role)
      const { status } = await rolePut({ id, role: roleVal.key })
      if (status === 'ok') {
        this.$message.success('修改成功')
        this.getList()
      } else {
        this.$message.error('修改失败')
      }
    },
    async handleDelete (row) {
      const { status } = await userDelete(row.id)
      if (status) {
        this.$message.success('删除成功')
        this.getList()
      } else {
        this.$message.error('删除失败')
      }
    },
    async handleAdd (data) {
      if (!data) return
      const { status, errorMsg } = await userCreate(data)
      if (status === 'ok') {
        this.$message.success('添加成功')
        this.isShowForm = false
        this.getList()
      } else {
        this.$message.error(`添加失败,${errorMsg}`)
      }
    },
    handlePageSize (pageSize) {
      this.pageSize = pageSize
    },
    handlePageNo (pageNo) {
      this.pageNo = pageNo
    }
  }
}
</script>

<style>
</style>
