// wxUser
export const tableCols = [{
  prop: 'username',
  label: '用户名'
}, {
  prop: 'phone',
  label: '手机号'
}, {
  prop: 'role',
  label: '权限',
  isSelect: true,
  optionContent: ['医生', '病人']
}, {
  prop: 'realName',
  label: '用户名'
}, {
  prop: 'password',
  label: '密码',
  type: 'password'
}, {
  prop: 'createUser',
  label: '创建人'
}, {
  prop: 'createTime',
  label: '创建时间',
  isSort: true
}, {
  prop: 'updateUser',
  label: '更新人'
}, {
  prop: 'updateTime',
  label: '更新时间',
  isSort: true
}]

export const roleConfig = [{
  key: 1,
  value: '管理员'
}, {
  key: 2,
  value: '医生'
}, {
  key: 3,
  value: '病人'
}]
