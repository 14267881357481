<template>
  <div class="card">
  <p class="title"><i class="fa fa-th-large fa-lg"></i>添加用户</p>
  <i class='delete-icon el-icon-close' @click="() => {$emit('onClose')}"></i>
  <el-form :model="ruleForm2" status-icon :rules="rules" ref="ruleForm2" label-width="100px" class="demo-ruleForm">
    <el-form-item label="用户名" prop="realName">
      <el-input clearable type='text' autosize v-model="ruleForm2.realName" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="账号" prop="username">
      <el-input clearable type='text' autosize v-model="ruleForm2.username" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="密码" prop="password">
      <el-input clearable show-password type="password" v-model="ruleForm2.password" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="确认密码" prop="checkPass">
      <el-input clearable show-password type="password" v-model="ruleForm2.checkPass" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="手机号" prop="phone">
      <el-input clearable type='phone' autosize v-model="ruleForm2.phone" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('ruleForm2')">确 定</el-button>
      <el-button @click="resetForm">重 置</el-button>
    </el-form-item>
  </el-form>
  </div>
</template>

<script>
export default {
  name: 'Form',
  data () {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (value.toString().length < 6) {
        callback(new Error('密码长度不能低于6位'))
      } else {
        if (this.ruleForm2.checkPass !== '') {
          this.$refs.ruleForm2.validateField('checkPass')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value.toString().length < 6) {
        callback(new Error('密码长度不能低于6位'))
      } else if (value !== this.ruleForm2.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      ruleForm2: {
        password: '',
        phone: '',
        realName: '',
        username: '',
        checkPass: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ],
        phone: [
          { message: '请输入手机号', trigger: 'blur' }
        ],
        realName: [
          { message: '请输入用户名', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const obj = {}
          for (const key in this.ruleForm2) {
            if (key !== 'checkPass') {
              obj[key] = this.ruleForm2[key]
            }
          }
          this.$emit('successAdd', obj)
        } else {
          this.$message.error('添加失败')
        }
      })
    },
    resetForm () {
      this.ruleForm2 = {
        password: '',
        phone: '',
        realName: '',
        username: '',
        checkPass: ''
      }
    }
  }
}
</script>
